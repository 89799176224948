import services from "../axios/dbManag";
import router from "../router/index";

export const approvaSanzioniMultiplaDaPagamento = (movimenti) => {
  return services.dbManag
    .post("/economato/sanzioni/movimenti/paga", {
      ...movimenti,
      return_url: window.location.href,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const getSanzioniImporto = (sanzioni, data_pagamento) => {
  return services.dbManag
    .post("/sanzioni/totale/da/pagare", {
      sanzioni: sanzioni,
      data_pagamento,
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};
